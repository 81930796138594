import { Component, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { LocationService } from './shared/helper/location.service';
import { config } from './shared/services/config';  
import { ApiService } from './shared/services/api.service';  
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );
  
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService, translate: TranslateService, private _LocationService: LocationService,
    private ApiService:ApiService) {
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('en');
      translate.addLangs(['en', 'de', 'es', 'fr', 'pt', 'cn', 'ae']);
    }
    this._LocationService.seterLocation.subscribe((letLang: any) => {
      if (letLang) {
         console.log(`ojjj`,letLang);
         localStorage.setItem('latlong', JSON.stringify(letLang)); 
      }
    })
    
  
  }
  ngOnInit() {
    
   
  }
}
