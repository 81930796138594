import { Injectable } from '@angular/core';
import { Router } from '@angular/router'; 
import { NgxToasterService } from '../shared/helper/toasterNgs.service';
import { ApiService } from '../shared/services/api.service';
import { config } from '../shared/services/config';
import { LocalStorageService } from '../shared/helper/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private apiRequest:ApiService,
    private sessionStorage: LocalStorageService,
    private route:Router,
    private ngrxToaster: NgxToasterService,) { }


  logout() {  
      let payload: FormData = new FormData()
      //this.isSessionTimeOut.next(false);
      this.apiRequest.postdata(payload, config.auth.logout).subscribe((resp: any) => { 
        //console.log(resp);
        
            if (resp.statuscode === 200) {
              this.ngrxToaster.showSuccess(resp.msg, undefined);
             this.sessionStorage.clearStorage();
             this.route.navigate(['/auth/login']);
             window.location.reload();
            }
           
          },(error: any) => { 
          }

      );  

  }


  changePassword(payload: any,retype:any){
      if(retype == 1){
        return this.apiRequest.postdata(payload,  config.auth.fchangePassword);
      }else{
        return this.apiRequest.postdata(payload,  config.auth.changepassword);
      }
   
  };

  getbalnce(payloadData: any){
    return this.apiRequest.postdata(payloadData,config.auth.userbalance);
  }

  getDashboardData(payloadData: any){
    return this.apiRequest.postdata(payloadData,config.auth.getTransactionData);
  }

}
