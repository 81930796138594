import { LocalStorageService } from './helper/local-storage.service';
import { CommonService } from './helper/common.service';
import { LoadingService } from './services/loading.service';
import { ExcelService } from './helper/excel.service';
import { ShowNullResultPipe } from './helper/show-null-result.pipe';

import { CredentialsService } from './../components/credentials/credentials.service';
import { PytFilterPipe } from './custome/components/pytCustTbl/pyt-filter.pipe';
import { PytPaginationComponent } from './custome/components/pytCustTbl/pytPagination/pyt-pagination/pyt-pagination.component';
import { PytTblTopBoxComponent } from './custome/components/pytCustTbl/pyt-tbl-top-box/pyt-tbl-top-box.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragulaModule } from 'ng2-dragula';
import { NgOtpInputModule } from 'ng-otp-input';
import { TranslateModule } from '@ngx-translate/core';
// Components
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CustomizerComponent } from './components/customizer/customizer.component';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { ContentComponent } from './components/layout/content/content.component';
import { FullComponent } from './components/layout/full/full.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';
// Header Elements Components
import { SearchComponent } from './components/header/elements/search/search.component';
import { MegaMenuComponent } from './components/header/elements/mega-menu/mega-menu.component';
import { LanguagesComponent } from './components/header/elements/languages/languages.component';
import { NotificationComponent } from './components/header/elements/notification/notification.component';
import { BookmarkComponent } from './components/header/elements/bookmark/bookmark.component';
import { CartComponent } from './components/header/elements/cart/cart.component';
import { MessageBoxComponent } from './components/header/elements/message-box/message-box.component';
import { MyAccountComponent } from './components/header/elements/my-account/my-account.component';
// Directives
import { DisableKeyPressDirective } from './directives/disable-key-press.directive';
import { OnlyAlphabetsDirective } from './directives/only-alphabets.directive';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { ShowOptionsDirective } from './directives/show-options.directive';
import { CdkCustomMdlModule } from '../shared/custome/custom-cdk-modal/cdk-custom-mdl.module';
import { CustomDynamicOtpModule } from '../shared/custome/components/custom-dynamic-otp/custom-dynamic-otp.module';
import { OtpModalModule } from '../shared/custome/modals/otp-modal/otp-modal.module';
// Services
import { ChatService } from './services/chat.service';
import { LayoutService } from './services/layout.service';
import { NavService } from './services/nav.service';
import { ApiService } from './services/api.service';
import { SessionStorageService } from './helper/session-storage.service';
import { NgxToasterService } from './helper/toasterNgs.service';
import { AdminSettingService } from '../components/admin-setting/admin-setting.service';
import { AuthService } from '../auth/auth.service';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule} from 'ngx-bootstrap/datepicker'
import { PytCustTblPagerDirective } from './custome/components/pytCustTbl/pytPagination/pyt-cust-tbl-pager.directive';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';  
@NgModule({
  declarations: [
    PytCustTblPagerDirective,
    PytTblTopBoxComponent,
    PytPaginationComponent,
    PytFilterPipe, 
    ShowNullResultPipe,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ContentComponent,
    BreadcrumbComponent,
    CustomizerComponent,
    FeatherIconsComponent,
    FullComponent,
    ShowOptionsDirective,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    LoaderComponent,
    TapToTopComponent,
    SearchComponent,
    MegaMenuComponent,
    LanguagesComponent,
    NotificationComponent,
    BookmarkComponent,
    CartComponent,
    MessageBoxComponent,
    MyAccountComponent
  ],
  imports: [
    BsDatepickerModule.forRoot(),
    CommonModule,
    RouterModule,
    FormsModule,
    CdkCustomMdlModule,
    OtpModalModule,
    CustomDynamicOtpModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    NgOtpInputModule,
    AutocompleteLibModule, 
    DragulaModule.forRoot(),
    TranslateModule,
    NgOtpInputModule, 
    ToastrModule.forRoot({
      autoDismiss: false, maxOpened: 1, preventDuplicates: true,
      positionClass: 'toast-bottom-center', closeButton: true
    }),
  ],
  providers: [
    NavService,
    ChatService,
    LayoutService, 
    ExcelService,
    ApiService,
    CommonService,
    AuthService, 
    LoadingService,
    CredentialsService,
    AdminSettingService,
    NgxToasterService,
    SessionStorageService,
    LocalStorageService,  
  ],
  exports: [
    PytCustTblPagerDirective,
    PytTblTopBoxComponent,
    PytPaginationComponent,
    PytFilterPipe, 
    ShowNullResultPipe,
    BsDatepickerModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    LoaderComponent,
    ToastrModule,
    NgOtpInputModule,
    BreadcrumbComponent,
    FeatherIconsComponent,
    TapToTopComponent,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    NgSelectModule,
    AutocompleteLibModule,   
  ],
})
export class SharedModule { }
