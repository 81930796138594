export const config = { 
    auth:{
        login:'auth/login',
        verifyotp:'auth/verify',
        validateotp:'auth/validate-otp',
        changepassword: 'change-password',
        fchangePassword: 'auth/fchangePassword',
        forgotpassword: 'auth/forgot-password',
        fpverifyotp: 'auth/login/fpverifyotp',
        logout:'user/logout',
        sendotp:'auth/sendotp',
        userbalance :'auth/userBalance',
        getTransactionData:"getTransactionData",
        sendOtpToMobile:'auth/sendotpTOmobile',
        VerifyOtp:'auth/verify',
        menu:'auth/getmenus'
    },
    user:{
      adduser:'user/user-create',
      userlist:'user/getalluser',
      alluser:'user/user-list',
      userlistbyid:'user/user-detail',
      userupdate:'user/update-user',
      update:'user/update-status',
      updateGstStatus:'user/update-GstStatus',
      userprofile:'user/profile',
      debitamountform:'fund/debit',
      userladditionadata:'user/get-altmobile',
      userServicePermission:'user/ServicePermission',
      updateuserladditionadata:'user/update-altmobile'
     }, 
     role:{
      rolelist:'role/role-list'
     },
     switchapi:{
      dth:'apis/list-switch-api-dth',
      rech:'apis/get-state-apis-list',
      rechargechangeapi:'apis/change-api',
      dthchangeapi:'apis/change-dth-api',
      deleterecharge:'apis/deleterecharge',
      deletedth:'apis/deletedth',
      addoplimit:'oplimit/addswitchop',
      listoplimit:'oplimit/getswitchop',
      changeopstatus:'oplimit/changeopstatus',
      listamountblock:'apis/getOpAmtBlock',
      addopamountblock:'apis/addOpAmtBlock',
      changeopblockamtstatus:'apis/changeopblockamtstatus',
      GetOperatorLimitUserComponent:'oplimit/getOplimitUser',
      addOplimitUser:'oplimit/storeOplimitUser',
      GetOperatorLimitReportComponent:'oplimit/getOplimitUserLedger',
      UpdateOplimitUser:'oplimit/UpdateOplimitUser',
      storeOplimitUser:'oplimit/storeOplimitUser'
    },
    fund:{
      // fundapprove:'fund/authorize/pending',
      fundapprove:'fund/authorize/getlistfund',
      approverequest:'fund/authorize/authorized',
      rejectrequest:'fund/authorize/reject',
      addfundadmin:'fund/add-fund',
      addfundsuperadmin:'fund/add-fund-superadmin',
      getfundbyid:'fund/authorize/getpendingById',
      exportdatarec:"fund-approve-download",
    },
    userfund:{
      addfund:'funding/createrequest',
      allrequest:'funding/getrequest',
      Downloadrequest:'funding/Downloadrequest',
      addonlinefund:'funding/pg-gateway'
    },
    bank:{
      banklist:'get-bank-list',
    },
    statement:{
      mobilerecharge:'statement',
      dthrecharge:'dthstatement',
      exportdatarec:"export-data-rec",
      exportdatadth:"export-data-dth",
      txnledger:'txn-ledger',
      holdtrans:'v1/getholdstatus',
      getholddthstatus:'v1/getholddthstatus',
      dayledgerRech:'dayledgerRech',
      dayledgerDth:"dayledgerDth",
      updatemanual:'updatemanual',
      updatemanualdth:'updatemanualdth',
      updateSucesstoFailedRec:'updateSucesstoFailedRec',
      updateSucesstoFailedDTHRec:'updateSucesstoFailedDTHRec',
      txnledgerDownload:"txn-ledger-download",
      loginlogs:'loginlogs',
      callbacklogs:'callbacklogs',
      invoicereport:'gst/get-allinvoice',
      userreqreslogs:'userapi-reqres',
      userDispute:'statement-dispute',
      apireqreslogs:'api-reqres',
      kycreport:'Kycstatement',
      bbpsreport:'billpaymentstatement',
      rehitrecharge:'rehitrecharge',
      raisedispute:'raise-dispute',
      dmtreport:'dmt-txn-list'
    },
    payslip:{
      slipimage:'upload/upload-image',
    },
    state:{
      statelist:'state/get-state',
      addstate:'state/add-state',
      getstatebyid:'state/getstateById',
      updatestatus:'state/updatestate',
      deletestate:'state/Statedestroy'
    },
    cron:{
       cronlist:'getcron', 
        updatestatus:'updateCron',
        getcron:"getcronbyId"
    },
    operator:{
      operatorlist:'apis/getprovider',
      addoperator:'apis/addprovider',
      getoperatorbyid:'apis/getproviderById',
      updateoperator:"apis/editprovider",
      updatestatus:"apis/updateproviderStatus",
      deleteoperator:"apis/deleteoperator"


    },
    apis:{
      addapis:'apis/addapis',
      addrecharge:'',
      apislist:'apis/getapis',
      updatestatus:'apis/updateStatus',
      getapisbyid:'apis/getapiById',
      apisdelete:'apis/apisdestroy',
      updateapis:'apis/editapis',
      addstateapis:'apis/add-state-apis', 
    },
    uploadsdata:{
      upload:'user/uploadfiles'
    },
    cred:{
      credlist:'credentials/list-credentials', 
      getUser_data: 'credentials/get-credential-byid',
      editCredentials_data: 'credentials/edit-credentials',
      addCredentials: 'credentials/add-credentials',
      addAuthorisedkey: 'credentials/assign-authorised-token',
      credentialList: "credentials/listof-credentials",
  
    },
    commission:{
      getcommission:'commission/get-commision',
      updatecommission:'commission/update-commision'
    },
    setting:{
      getsetting:'settings/getsettingsById',
      updatesetting:'settings/updatesettings',
      updateip:'credentials/updateip',
      updatecallback:'credentials/updatecallback'
    },
    txnledger:{
      superadmintxnledgerist:'super-txn-ledger',
      admintxnledgerist:'admin-txn-ledger',
      txnledgerDownload:"txn-ledger-download",
    }
    

    
};